import React, { FunctionComponent } from 'react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import HeadphoneKidIcon from '../../../assets/SvgIcons/HeadphoneKidIcon';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../common/constants';
import HomeActionButton from './HomeActionButton';
import SmartLink from '../../SmartLink';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contactIcon: (theme: Theme) => ({
        width: '21px',
        height: '22px',
        color: theme.palette.text.primary,
        marginRight: '10px',
    }),
    contactPhone: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        color: theme.palette.text.primary,
    }),
};

const LoanSupportNumberWithIcon: FunctionComponent<BoxProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { consumerOperationsDetails: { phoneNumber }} = useConfig() as any;

    return <React.Fragment>
    { !isMobile
        ? <Box { ...props } sx={ styles.container }>
            <HeadphoneKidIcon sx={ styles.contactIcon }/>
            <SmartLink
                href={ `tel:${ phoneNumber }` }
                style={ (styles.contactPhone as any)(theme) }
            >
                { phoneNumber }
            </SmartLink>
        </Box>
        : <Box { ...props }>
            <HomeActionButton
                data-testid="btn-call-phonenum"
                href={ `tel:${ phoneNumber }` }
            >
                { `Call ${ phoneNumber }` }
            </HomeActionButton>
        </Box>
    }
    </React.Fragment>;
};

export default LoanSupportNumberWithIcon;
