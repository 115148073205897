import React, { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

const styles = {
    button: (theme: Theme) => ({
        borderRadius: '30px',
        marginTop: '20px',
        maxWidth: 460,
        width: '100%',
        height: 80,
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '25.78px',
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    }),
};

const MobileSubmitButton: FunctionComponent<{
    text: string,
    buttonProps?: ButtonProps,
}> = ({ text, buttonProps }) => {
    return <Button sx={ styles.button } { ...buttonProps }>
        { text }
    </Button>;
};

export default MobileSubmitButton;
