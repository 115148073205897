import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { isLight } from '../../../../common/helpers';
import { SurveySaveProps } from '../index';
import Typography from '@mui/material/Typography';
import Promo from '../Promo';
import MobileSubmitButton from './MobileSubmitButton';
import MobileSupportInfo from '../MobileSupportInfo';
// import ChatWithManager from '../../../ChatWithManager';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import { useRouter } from 'next/router';

const styles = {
    mobileContent: (theme: Theme) => ({
        padding: '30px 20px',
        backgroundColor: isLight(theme)
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    title: {
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
    },
    subTitle: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    discountUsageInfo: (theme: Theme) => ({
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.41px',
        color: theme.palette.warning.light,
        marginTop: '25px',
    }),
    mobileSupportInfo: {
        padding: '40px 20px 16px',
    },
    badge: {
        position: 'relative',
    },
};

const MobileSavedMainContent: FunctionComponent<SurveySaveProps> = ({
    title,
    discount,
    discountInfo,
    discountLoanInfo,
    discountUsageInfo,
    buttonText,
}) => {
    const router = useRouter();

    return <Box>
        <Box sx={ styles.mobileContent }>
            <Typography variant="h2" color="white" sx={ styles.title }>
                { title }
            </Typography>
            <Typography
                variant="h3"
                color="white"
                sx={ styles.subTitle }
                marginTop="5px"
                textAlign="center"
            >
                { discountInfo }
            </Typography>
            <Promo
                promoCode={ discount }
                customStyles={{ marginTop: '35px' }}
            />
            <Typography
                variant="h3"
                color="white"
                sx={ styles.subTitle }
                marginTop="35px"
                textAlign="center"
            >
                { discountLoanInfo }
            </Typography>
            <Box sx={ styles.buttonContainer }>
                <MobileSubmitButton
                    text={ buttonText }
                    buttonProps={{ onClick: () => router.push(
                        NamedRoute.HOME + '?dialog=apply',
                    )}}
                />
            </Box>
            <Box sx={ styles.discountUsageInfo }>
                { discountUsageInfo }
            </Box>
        </Box>
        <Box sx={ styles.mobileSupportInfo }>
            <MobileSupportInfo/>
            {/*Temporarily removed at the request of Collections*/}
            {/*<ChatWithManager sx={ styles.badge }/>*/}
        </Box>
    </Box>;
};

export default MobileSavedMainContent;
