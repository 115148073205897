import React, { FunctionComponent } from 'react';
import HomePaperPad from '../../../PaperPad';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, TextField } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import DesktopSubmitButton from './DesktopSubmitButton';
import { InitProps } from '../index';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';
import { isLight } from '../../../../common/helpers';

const styles = {
    paper: {
        padding: '40px 40px 55px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        rowGap: '45px',
    },
    contentTitle: {
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '35.16px',
    },
    answerField: (theme: Theme, error?: string) => ({
        width: '100%',
        height: '280px',
        '& > div': {
            padding: '25px 35px',
            backgroundColor: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main
            ,
            borderRadius: '20px',
            alignItems: 'start',
            overflow: 'auto',
            height: '100%',
        },
        '& > div > textarea': {
            color: isLight(theme) ? '#404040' : theme.palette.text.primary,
        },
        '& > div > fieldset': {
            borderRadius: '20px',
            border: `1px ${ error
                ? `solid ${ theme.palette.error.main } !important`
                : '' }`,
        },
    }),
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
};

const DesktopInitMainContent: FunctionComponent<{
    placeholder: string,
    buttonText: string,
} & InitProps> = ({
    placeholder,
    buttonText,
    error,
    question,
    answer,
    setAnswer,
    onClick,
    setError,
    loading,
}) => {
    const theme = useTheme();

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(undefined);

        if (question?.id) {
            setAnswer({
                text: event.target.value,
                questionId: question.id,
            });
        }
    };

    return <HomePaperPad sx={ styles.paper }>
        <Box sx={ styles.content }>
            <Box>
                <Typography variant="h2" sx={ styles.contentTitle }>
                    { question?.text }
                </Typography>
                <Box marginTop="30px">
                    { !!error && <Alert
                        severity="error"
                        iconMapping={ { error: <WarningIcon /> } }
                    >
                        { error }
                    </Alert> }
                    <TextField
                        sx={ styles.answerField(theme, error) }
                        multiline
                        placeholder={ placeholder }
                        value={ answer?.text }
                        onChange={ handleAnswerChange }
                    />
                </Box>
            </Box>
            <Box sx={ styles.buttonContainer }>
                <DesktopSubmitButton
                    text={ buttonText }
                    buttonProps={{
                        disabled: !answer?.text || loading,
                        onClick: onClick,
                    }}
                />
            </Box>
        </Box>
    </HomePaperPad>;
};

export default DesktopInitMainContent;
