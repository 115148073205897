import React, { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { isDark, isLight } from '../../../../common/helpers';
import { SxProps, useTheme } from '@mui/material';

const styles = {
    button: (theme: Theme) => ({
        borderRadius: '30px',
        maxWidth: 460,
        width: '100%',
        height: 80,
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '28.13px',
        color: 'white',
        backgroundColor: isDark(theme)
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.secondary.light
                : theme.palette.primary.light
            ,
        },
    }),
};

const DesktopSubmitButton: FunctionComponent<{
    text: string,
    customStyles?: SxProps<Theme>,
    buttonProps?: ButtonProps,
}> = ({ text, customStyles, buttonProps }) => {
    const theme = useTheme();

    return <Button sx={{
        ...styles.button(theme),
        ...(typeof customStyles === 'function'
            ? customStyles(theme)
            : customStyles
        ),
    }} { ...buttonProps }>
        { text }
    </Button>;
};

export default DesktopSubmitButton;
