import { ApiClient, isLight } from '../../../common/helpers';
import BaseLayout from '../../../layouts/BaseLayout';
import MembersAreaPageWrapper from '../MembersAreaPageWrapper';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Footer } from '../../../layouts/PageLayout/Desktop';
import { useTheme } from '@mui/material/styles';
import SurveySideBar from './SurveySideBar';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../../common/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import Head from 'next/head';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import MobileLayout from '../../../layouts/PageLayout/Mobile';
import PageLoader from '../PageLoader';
import DesktopSubHeader from './Desktop/DesktopSubHeader';
import DesktopInitMainContent from './Desktop/DesktopInitMainContent';
import MobileInitMainContent from './Mobile/MobileInitMainContent';
import DesktopSavedMainContent from './Desktop/DesktopSavedMainContent';
import MobileSavedMainContent from './Mobile/MobileSavedMainContent';
import SurveyErrorAlert from './SurveyErrorAlert';
import { useRouter } from 'next/router';
import asyncLogger from '../../../common/logger';
import { login } from '../../../common/helpers/login';
import useAuthorized from '../../../common/hooks/useAuthorized';

export interface SurveySaveProps {
    title: string,
    discountInfo: string,
    discount: string,
    discountLoanInfo: string,
    buttonText: string,
    discountUsageInfo: string,
}

interface Question {
    id: string;
    campaignId: string;
    text: string;
}

interface SurveyQuestion {
    questions: Question[];
    customerId: string;
}

export interface InitProps {
    error?: string;
    question?: Question;
    answer?: Answer;
    setAnswer: (value: Answer) => void,
    onClick: () => void;
    setError: (value: string | undefined) => void,
    loading: boolean;
}

interface Answer {
    questionId: string;
    text: string;
}

const Survey: FunctionComponent = () => {
    const router = useRouter();
    const { t, tu } = router.query;
    const token = t || (tu
        ? new URL(tu as string).searchParams.get('t')
        : undefined);
    const mockInitData = {
        title: 'Survey',
        headerTitle: 'Welcome to CreditCube!',
        headerSubTitleInfo: 'Thanks for considering helping us to improve '
            + 'our services! After answering this question, you will get a ',
        headerSubTitlePromoInfo: '$25 discount code for your next loan.',
        mainContentPlaceholder: 'Type your answer here...',
        mainContentButtonText: 'Submit answer',
        headerSubTitleMobileInfo: 'After answering a question below, you will '
            + 'get a ',
    };

    const mockSavedData = {
        title: 'Thanks!',
        discountInfo: 'Your discount code for $25-off first payment in your '
            + 'next loan (sent to your email)*',
        discount: 'FP25OFF',
        discountLoanInfo: 'To use discount code create a loan application',
        buttonText: 'Apply for a loan',
        discountUsageInfo: '* To use this discount code, mention it by '
            + 'calling us or by sending en email.',
    };

    const { consumerName } = useConfig();
    const theme = useTheme();
    const authorized = useAuthorized();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );
    const headerBackground = isLight(theme)
        ? theme.palette.primary.dark
        : theme.palette.primary.main;

    const [loadLoading, setLoadLoading] = useState(true);
    const [saved, setSaved] = useState(false);
    const [loadError, setLoadError] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const [
        question,
        setQuestion,
    ] = useState<SurveyQuestion | undefined>(undefined);
    const [answer, setAnswer] = useState<Answer | undefined>(undefined);
    const [saveLoading, setSaveLoading] = useState(false);

    const pageTitle = `${ consumerName }: ${ mockInitData.title }`;

    useEffect(() => {
        if (!token && !authorized) {
            return ;
        }

        (async(): Promise<void> => {
            try {
                const { data } = await ApiClient.request(
                    '/surveyQuestion',
                    { body: { token }, withAuth: false },
                );

                if (data?.authToken) {
                    login(data.authToken);
                }

                if (data?.surveyQuestion) {
                    const surveyQuestion = data.surveyQuestion.surveyQuestion;

                    if (surveyQuestion.answered
                        && !surveyQuestion.allowMultipleResponse
                    ) {
                        setSaved(true);
                    } else {
                        setQuestion({
                            customerId: data.surveyQuestion.customerId,
                            questions: surveyQuestion.questions,
                        });
                    }

                    setLoadLoading(false);

                    return ;
                }
            } catch (err) {
                asyncLogger.error(err);
            }

            setLoadError(true);
            setLoadLoading(false);
        })();
    }, [token, authorized]);

    const handleSubmit = async () => {
        if (!question?.customerId) {
            setSaveError('Customer is not identified!');

            return ;
        }

        if (!answer) {
            setSaveError('Answer cannot be saved!');

            return ;
        }

        setSaveLoading(true);

        try {
            const { data } = await ApiClient.request(
                '/saveSurveyQuestion',
                {
                    body: {
                        input: {
                            customerId: question.customerId,
                            answers: [{ ...answer }],
                        },
                    }, withAuth: true },
            );

            if (data?.saveSurveyAnswer?.saved) {
                setSaved(true);
                setSaveLoading(false);

                return ;
            }
        } catch (err) {
            asyncLogger.error(err);
        }

        setSaveError('Unexpected error happened! Please try again later.');
        setSaveLoading(false);
    };

    if (loadError || (!authorized && !token)) {
        return <SurveyErrorAlert/>;
    }

    if (loadLoading) {
        return <PageLoader/>;
    }

    return <Fragment>
        <Head>
            <title>{ pageTitle }</title>
        </Head>
        { isDesktop
            ? <BaseLayout
                title={ 'Welcome to CreditCube!' }
                withActions={ false }
                footer={ <Footer withChatWithManager={ true }/> }
                withChatWithManager
                headerBottomSticky={ {
                    element: <DesktopSubHeader
                        title={ mockInitData.headerTitle }
                        subTitleInfo={ mockInitData.headerSubTitleInfo }
                        subTitlePromoInfo={
                            mockInitData.headerSubTitlePromoInfo
                        }
                    />,
                    zIndex: 10,
                    top: 0,
                    disableSticky: true,
                } }
            >
                <MembersAreaPageWrapper
                    content={ !saved ? <DesktopInitMainContent
                        question={ question?.questions[0] }
                        placeholder={ mockInitData.mainContentPlaceholder }
                        buttonText={ mockInitData.mainContentButtonText }
                        error={ saveError }
                        answer={ answer }
                        setAnswer={ setAnswer }
                        onClick={ handleSubmit }
                        setError={ setSaveError }
                        loading={ saveLoading }
                    /> : <DesktopSavedMainContent
                        title={ mockSavedData.title }
                        buttonText={ mockSavedData.buttonText }
                        discountInfo={ mockSavedData.discountInfo }
                        discount={ mockSavedData.discount }
                        discountLoanInfo={ mockSavedData.discountLoanInfo }
                        discountUsageInfo={ mockSavedData.discountUsageInfo }
                    /> }
                    sidebar={ <SurveySideBar/> }
                    fullWidth
                />
            </BaseLayout>
            : <MobileLayout
                title={ mockInitData.title }
                withActions={ false }
                headerBottomSticky={{
                    element: <></>,
                    zIndex: 100,
                    disableSticky: true,
                }}
                headerBackground={ headerBackground }
            >
                { !saved ? <MobileInitMainContent
                    subTitleInfo={ mockInitData.headerSubTitleMobileInfo }
                    subTitlePromoInfo={ mockInitData.headerSubTitlePromoInfo }
                    question={ question?.questions[0] }
                    placeholder={ mockInitData.mainContentPlaceholder }
                    buttonText={ mockInitData.mainContentButtonText }
                    error={ saveError }
                    answer={ answer }
                    setAnswer={ setAnswer }
                    onClick={ handleSubmit }
                    setError={ setSaveError }
                    loading={ saveLoading }
                /> : <MobileSavedMainContent
                    title={ mockSavedData.title }
                    buttonText={ mockSavedData.buttonText }
                    discountInfo={ mockSavedData.discountInfo }
                    discount={ mockSavedData.discount }
                    discountLoanInfo={ mockSavedData.discountLoanInfo }
                    discountUsageInfo={ mockSavedData.discountUsageInfo }
                /> }
            </MobileLayout>
        }
    </Fragment>;
};

export default Survey;
