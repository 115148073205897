import { Theme, useTheme } from '@mui/material/styles';
import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { isLight, useFooterStyles } from '../../common/helpers';
import { useConfig } from '../../common/hooks/ConfigProvider';
import ConsumerYearMobileInfo from './ConsumerYearMobileInfo';
import SmartLink from '../../components/SmartLink';
import { EXTRA_SMALL } from '../../common/constants';

const styles = {
    footerMobile: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.action.active
            : theme.palette.secondary.main
        ,
        height: '60px',
        borderRadius: '20px',
        marginTop: '20px',
    }),
    footerInfoMobile: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '24px',
        paddingBottom: '22px',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '8px',
        },
    }),
};

const MobileFooter: FunctionComponent = () => {
    const { consumerName } = useConfig();
    const classes = useFooterStyles({});

    return <Box sx={ styles.footerMobile }>
        <Box sx={ styles.footerInfoMobile }>
            <ConsumerYearMobileInfo />
            <SmartLink href="/" className={ classes.footerLink }>
                Go to { consumerName } site
            </SmartLink>
        </Box>
    </Box>;
};

export default MobileFooter;
