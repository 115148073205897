import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import {
    KnownBreakpoints,
    THEME_BREAKPOINTS,
} from '../../../../common/constants';

const styles = {
    headerContainer: {
        height: 300,
        padding: 0,
    },
    headerBox: (theme: Theme) => ({
        border: '1px solid white',
        borderRadius: '20px',
        padding: '25px 40px 20px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            padding: '15px 20px 10px',
        },
    }),
    title: (theme: Theme) => ({
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '34px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            fontSize: '24px',
            lineHeight: '30px',
        },
    }),
    subTitleInfo: (theme: Theme) => ({
        fontSize: '30px',
        fontWeight: 400,
        lineHeight: '34px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            fontSize: '24px',
            lineHeight: '30px',
        },
    }),
    subTitlePromoInfo: (theme: Theme) => ({
        fontSize: '30px',
        fontWeight: 400,
        lineHeight: '34px',
        color: theme.palette.warning.light,
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            fontSize: '24px',
            lineHeight: '30px',
        },
    }),
};

const DesktopSubHeader: FunctionComponent<{
    title: string,
    subTitleInfo: string,
    subTitlePromoInfo: string,
}> = ({ title, subTitlePromoInfo, subTitleInfo  }) => {
    return <Box sx={ styles.headerContainer }>
        <Box sx={ styles.headerBox }>
            <Typography
                variant="h2"
                color="white"
                sx={ styles.title }
            >
                { title }
            </Typography>
            <Typography
                variant="h3"
                color="white"
                sx={ styles.subTitleInfo }
            >
                { subTitleInfo }
                <Typography sx={ styles.subTitlePromoInfo } component="span">
                    { subTitlePromoInfo }
                </Typography>
            </Typography>
        </Box>
    </Box>;
};

export default DesktopSubHeader;
