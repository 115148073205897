import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { IncomingMessage } from 'http';
import CmsClient from '../../common/clients/CmsClient';
import {
    currentPage,
    NamedRoute,
    PageRoute,
    RouteContext,
    RouteProps,
} from '../../common/hooks/useNamedRoute';
import { getPathMatch } from 'next/dist/shared/lib/router/utils/path-match';
import { useRouter } from 'next/router';
import PageLoader from '../../components/Pages/PageLoader';
import Error404 from '../404';
import { cache, generateKey } from '../../common/StaticCache';
import { isAuthorized } from '../../common/helpers/login';
import useAuthorized from '../../common/hooks/useAuthorized';
import {
    PageProps,
    RedirectProps,
    RESOURCE,
} from '../../common/types/CmsEntities';
import { useConfig } from '../../common/hooks/ConfigProvider';
import { getEnabledScreen } from '../../common/helpers/getEnabledScreen';
import MaintenanceComponent from '../../components/MaintenanceComponent';
import CustomersLanding from '../../components/Pages/Account/CustomersLanding';
import Survey from '../../components/Pages/Survey';

const LoginPage = dynamic(
    () => import('../../components/Pages/Login'),
    { loading: () => <PageLoader />, ssr: false },
);
const LogoutPage = dynamic(
    () => import('../../components/Pages/Logout'),
    { loading: () => <PageLoader />, ssr: false },
);
const RemindPasswordPage = dynamic(
    () => import('../../components/Pages/RemindPassword'),
    { loading: () => <PageLoader />, ssr: false },
);
const ResetPasswordPage = dynamic(
    () => import('../../components/Pages/ResetPassword'),
    { loading: () => <PageLoader />, ssr: false },
);
const AccountPage = dynamic(
    () => import('../../components/Pages/Account'),
    { loading: () => <PageLoader />, ssr: false },
);
const BankVerificationPage = dynamic(
    () => import('../../components/Pages/Ibv'),
    { loading: () => <PageLoader />, ssr: false },
);
const ESigPage = dynamic(
    () => import('../../components/Pages/ESig'),
    { loading: () => <PageLoader />, ssr: false },
);
const CreateApplicationPage = dynamic(
    () => import('../../components/Pages/CreateApplication'),
    { loading: () => <PageLoader />, ssr: false },
);
const DBSigPage = dynamic(
    () => import('../../components/Pages/DBSig'),
    { loading: () => <PageLoader />, ssr: false },
);
const DebitCardPage = dynamic(
    () => import('../../components/Pages/DebitCard'),
    { loading: () => <PageLoader />, ssr: false },
);
const ProfilePage = dynamic(
    () => import('../../components/Pages/Profile'),
    { loading: () => <PageLoader />, ssr: false },
);
const LoanHistoryPage = dynamic(
    () => import('../../components/Pages/Profile/LoanHistory'),
    { loading: () => <PageLoader />, ssr: false },
);
const EntityVerificationPage = dynamic(
    () => import('../../components/Pages/EntityVerification'),
    { loading: () => <PageLoader />, ssr: false },
);
const SellLeadPage = dynamic(
    () => import('../../components/Pages/SellLead'),
    { loading: () => <PageLoader />, ssr: false },
);
const CurrentBalancePage = dynamic(
    () => import('../../components/Pages/CurrentBalance'),
    { loading: () => <PageLoader />, ssr: false },
);

const disallowRedirect: { [route: string]: true | undefined } = {
    [NamedRoute.BANK_VERIFICATION]: true,
    [NamedRoute.SIGN_AGREEMENT]: true,
    [NamedRoute.SIGN_ADDENDUM]: true,
    [NamedRoute.CREATE_APPLICATION]: true,
    [NamedRoute.ADD_DEBIT_CARD]: true,
    [NamedRoute.VERIFY_ENTITY]: true,
    [NamedRoute.LOGIN]: true,
    [NamedRoute.LOGOUT]: true,
    [NamedRoute.SEARCH_PARTNER]: true,
    [NamedRoute.CURRENT_BALANCE]: true,
    [NamedRoute.CUSTOMERS_LANDING]: true,
    [NamedRoute.SURVEY]: true,
};

const RX_JSON = /\.json$/;
const RX_PASSWORD = /\/app\/password\/.*?/;

function needRedirect(
    authorized: boolean,
    pagePath: string,
    reqUrl?: any,
): boolean {
    return (
        !RX_JSON.test(pagePath) &&
        !RX_PASSWORD.test(pagePath) &&
        !disallowRedirect[pagePath] &&
        !reqUrl?.includes('createPP') &&
        !reqUrl?.includes('rated') &&
        !authorized
    );
}

export const getServerSideProps = async (
    { req }: { req: IncomingMessage },
): Promise<{ props?: PageProps, redirect?: RedirectProps }> => {
    const cacheKey = generateKey('menu');
    let menuData = await cache.get(cacheKey);

    if (!menuData) {
        menuData = await Promise.all([
            CmsClient.getEntities(req, RESOURCE.menu),
        ]);
    }

    const [{ data: menu }] = menuData;
    const authorized = isAuthorized(req);
    const [currentPage, query] = req.url?.split('?') || [];

    if (needRedirect(authorized, currentPage, req.url)) {
        return {
            redirect: {
                permanent: false,
                destination: NamedRoute.LOGIN + (query ? '?' + query : ''),
            },
        };
    }

    if (currentPage.includes(NamedRoute.CREATE_APPLICATION) && authorized) {
        return {
            redirect: {
                permanent: false,
                destination: NamedRoute.HOME + '?dialog=apply',
            },
        };
    }

    return { props: { menu: menu[0], authorized } };
};

export const pages: {
    path: string;
    component: React.JSX.Element;
}[] = [
    { path: NamedRoute.HOME, component: <AccountPage /> },
    { path: NamedRoute.LOGIN, component: <LoginPage /> },
    { path: NamedRoute.LOGOUT, component: <LogoutPage /> },
    { path: NamedRoute.PASSWORD_REMIND, component: <RemindPasswordPage /> },
    { path: NamedRoute.PASSWORD_RESET, component: <ResetPasswordPage /> },
    { path: NamedRoute.PROFILE, component: <ProfilePage /> },
    { path: NamedRoute.LOAN_HISTORY, component: <LoanHistoryPage /> },
    { path: NamedRoute.BANK_VERIFICATION, component: <BankVerificationPage /> },
    { path: NamedRoute.SIGN_AGREEMENT, component: <ESigPage /> },
    {
        path: NamedRoute.CREATE_APPLICATION,
        component: <CreateApplicationPage />,
    },
    { path: NamedRoute.SIGN_ADDENDUM, component: <DBSigPage /> },
    { path: NamedRoute.ADD_DEBIT_CARD, component: <DebitCardPage /> },
    { path: NamedRoute.VERIFY_ENTITY, component: <EntityVerificationPage /> },
    { path: NamedRoute.SEARCH_PARTNER, component: <SellLeadPage /> },
    { path: NamedRoute.CURRENT_BALANCE, component: <CurrentBalancePage /> },
    { path: NamedRoute.CUSTOMERS_LANDING, component: <CustomersLanding /> },
    { path: NamedRoute.SURVEY, component: <Survey /> },
];

export const Switch: React.FunctionComponent<PropsWithChildren> = props => {
    const pagePath = currentPage(useRouter());
    let matched = false;

    for (const routePath of Object.values(NamedRoute)) {
        matched = !!getPathMatch(routePath)(pagePath);

        if (matched) {
            // route is ok, check if the page is implemented
            matched = !!pages.find(({ path }) =>
                path === routePath,
            );
        }

        if (matched) {
            break;
        }
    }

    if (!matched) {
        return <Error404 />;
    }

    return <React.Fragment>{ props.children }</React.Fragment>;
};

export const Route: React.FunctionComponent<RouteProps> = props => {
    const { children, path } = props;
    const router = useRouter();
    const pagePath = currentPage(router);
    const params = getPathMatch(path || '')(pagePath);
    const matched = params !== false;

    if (!matched) {
        return null;
    }

    const pageRoute = {
        path,
        pagePath,
        params: params || {},
    } as PageRoute;

    return <RouteContext.Provider value={ pageRoute }>
        { children }
    </RouteContext.Provider>;
};

const App: FunctionComponent<PageProps> = () => {
    const authorized = useAuthorized();
    const router = useRouter();
    const pagePath = currentPage(router);
    const { errorScreens, consumerOperationsDetails } = useConfig();
    const highestPriorityEnabledScreen = getEnabledScreen(
        errorScreens,
        false,
    );

    useEffect(() => {
        if (needRedirect(authorized, pagePath, router.asPath)) {
            router.push(NamedRoute.LOGIN).catch();
        }
        // eslint-disable-next-line
    }, [authorized, pagePath]);

    if (highestPriorityEnabledScreen) {
        return <MaintenanceComponent
            { ...highestPriorityEnabledScreen }
            phoneNumber={ consumerOperationsDetails?.phoneNumber }
        />;
    }

    return (needRedirect(authorized, pagePath, router.asPath)
        ? <PageLoader />
        : <Switch>
            { pages.map(({ path, component }, i) =>
                <Route path={ path } key={ i }>{ component }</Route>,
            ) }
        </Switch>
    );
};

export default App as NextPage;
