import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ErrorIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 96 83'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M4.34922 82.5C3.07422 82.5 2.09922 81.9375 1.42422 80.8125C0.749219 79.6875 0.749219 78.5625 1.42422 77.4375L45.0742 2.0625C45.7492 0.9375 46.7242 0.375 47.9992 0.375C49.2742 0.375 50.2492 0.9375 50.9242 2.0625L94.5742 77.4375C95.2492 78.5625 95.2492 79.6875 94.5742 80.8125C93.8992 81.9375 92.9242 82.5 91.6492 82.5H4.34922ZM48.4492 31.65C47.4742 31.65 46.668 31.9688 46.0305 32.6063C45.393 33.2438 45.0742 34.05 45.0742 35.025V53.475C45.0742 54.45 45.393 55.2562 46.0305 55.8937C46.668 56.5312 47.4742 56.85 48.4492 56.85C49.4242 56.85 50.2305 56.5312 50.868 55.8937C51.5055 55.2562 51.8242 54.45 51.8242 53.475V35.025C51.8242 34.05 51.5055 33.2438 50.868 32.6063C50.2305 31.9688 49.4242 31.65 48.4492 31.65ZM48.4492 69.3375C49.4242 69.3375 50.2305 69.0187 50.868 68.3812C51.5055 67.7437 51.8242 66.9375 51.8242 65.9625C51.8242 64.9875 51.5055 64.1813 50.868 63.5438C50.2305 62.9062 49.4242 62.5875 48.4492 62.5875C47.4742 62.5875 46.668 62.9062 46.0305 63.5438C45.393 64.1813 45.0742 64.9875 45.0742 65.9625C45.0742 66.9375 45.393 67.7437 46.0305 68.3812C46.668 69.0187 47.4742 69.3375 48.4492 69.3375Z'
                }
                fill={ props.fill || theme.palette.error.main }
            />
        </SvgIcon>
    );
}

export default ErrorIcon;
