import React, { FunctionComponent, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import ErrorIcon from '../../../assets/SvgIcons/ErrorIcon';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../../common/constants';
import DialogAlert from '../DialogAlert';

const styles = {
    dialogIcon: {
        fontSize: '81px',
    },
};

const SurveyErrorAlert: FunctionComponent = () => {
    const router = useRouter();

    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );

    const [openAlert, setOpenAlert] = useState(true);

    const title = isDesktop
        ? 'Unexpected error! Unfortunately we couldn\'t load the survey '
        + 'questions.'
        : 'Unexpected error!'
    ;
    const subtitle = isDesktop
        ? undefined
        : 'Unfortunately we couldn\'t load the survey questions.'
    ;

    return <DialogAlert
        icon={ <ErrorIcon
            fill={ theme.palette.error.dark }
            sx={ styles.dialogIcon }
        /> }
        variant="error"
        title={ title }
        subtitle={ subtitle }
        withCallButton={ true }
        buttonTitle={ 'Get back to the account!' }
        buttonProps={{ onClick: () => {
                setOpenAlert(false);

                router.push(NamedRoute.HOME).then();
            }}}
        dialogProps={{
            open: openAlert,
            onClose: () =>  {
                setOpenAlert(false);

                router.push(NamedRoute.HOME).then();
            },
        }}
    />;
};

export default SurveyErrorAlert;
