import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import BaseLayout from '../../../layouts/BaseLayout';
import { useTheme } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Checkbox,
    List,
    ListItem,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Footer } from '../../../layouts/PageLayout/Desktop';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GradingIcon from '@mui/icons-material/Grading';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import { useRouter } from 'next/router';
import {
    ApiClient,
    isDark as isDarkTheme,
    RestMethod,
} from '../../../common/helpers';
import { paletteDark, paletteLight } from '../../../common/palettes';
import {
    COMPLETE_BUTTON_BREAKPOINT,
    EXTRA_SMALL,
    KnownBreakpoints,
    SMALL,
    SUB_LAPTOP,
    TABLE_BREAKPOINT,
    THEME_BREAKPOINTS,
} from '../../../common/constants';
import { login } from '../../../common/helpers/login';
import { useAccountInfo } from '../../../common/hooks/AccountInfoProvider';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import PageLoader from '../PageLoader';
import asyncLogger from '../../../common/logger';
import Head from 'next/head';
import LoanSupportNumberWithIcon from './LoanSupportNumberWithIcon';
import { officeHoursFormat } from '../../../common/helpers/format';
import IsOfficeOpened from '../../SupportInfo/IsOfficeOpened';
import CheckboxIcon from '../../../assets/SvgIcons/CheckboxIcon';
import CheckboxCheckedIcon from '../../../assets/SvgIcons/CheckboxCheckedIcon';
import CheckboxIndeterminateIcon
    from '../../../assets/SvgIcons/CheckboxIndeterminateIcon';
import useAuthorized from '../../../common/hooks/useAuthorized';
import CustomersLandingPopup from '../../CustomersLandingPopup';

const mockData = {
    title: 'Welcome to CreditCube!',
    subTitle:
        'Thank you for considering our services. We try our best to provide ' +
        'a transparent and seamless service.',
    mobileSubTitle:
        'To finalize your loan application please follow these 3 steps:',
    steps: [{
        title: '1. Verify Bank Account',
        description:
            'Make sure your account information is accurate so we can send ' +
            'you the funds.',
        icon: <AssuredWorkloadIcon sx={{
            width: 48,
            height: 48,
            color: 'white',
        }}/>,
    }, {
        title: '2. E-sign Your Docs',
        description: 'Review your loan documents and terms of payments ' +
            'before final approval.',
        icon: <GradingIcon sx={{
            width: 48,
            height: 48,
            color: 'white',
        }}/>,
    }, {
        title: '3. Confirmation Call',
        description:
            'We may require a quick call to finalize your application, for '
            + 'this, please opt-in below. You can change your permissions at '
            + 'any time.',
        icon: <SupportAgentIcon sx={{
            width: 48,
            height: 48,
            color: 'white',
        }}/>,
    }],
    buttonText: 'Complete Application',
    phoneCheckbox: 'I consent to receive communication regarding my '
        + 'application and account from CreditCube via phone or other '
        + 'telephony service.',
    textMsgCheckbox: 'I consent to receive communication regarding my '
        + 'application, account, including payment reminders from CreditCube '
        + 'via TXT messages.',
};

const faqData = [{
    question: 'What is required to apply for a loan?',
    answer: [
        'You should be at least 18 years old.',
        <>
            You live in a{ ' ' }
            <Link href="/faq#map" style={ {
                color: paletteLight.palette.primary.main,
                textDecoration: 'underline',
            } }>
                state we service
            </Link>.
        </>,
        'You have an active and valid checking account (savings and ' +
        'prepaid accounts are not accepted).',
        'You have a job or a regular proven source of income.',
        'You have an email address and a verifiable phone number.',
    ],
}, {
    question: 'What type of bank account do I need to qualify for a loan?',
    answer:
        'You will need a valid checking account that accepts ACH credit ' +
        'and debits. Savings and prepaid accounts are not accepted.',
}, {
    question: 'When do I get the results of my application?',
    answer:
        'Your results should be available within seconds of submitting ' +
        'your application. We may need to contact you to verify your ' +
        'identity, income and banking information before the loan is ' +
        'approved. In the event we are unable to approve your loan, ' +
        'we\'ll provide you a reason for denial.',
}, {
    question: 'Where can I find the status of my loan application?',
    answer:
        'You can login into your account page by clicking the sign-in ' +
        'button at the top of the screen. Once logged in, you can find ' +
        'the status of your application and update your information.',
}];

const CustomersLanding: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );
    const router = useRouter();
    const { reload, customer } = useAccountInfo();
    const authorized = useAuthorized();
    const { t, tu } = router.query;
    const token = t || router.query.token || (tu
        ? new URL(tu as string).searchParams.get('t')
        : undefined
    );
    const isDark = isDarkTheme(theme);
    const {
        consumerName,
        consumerOperationsDetails: { officeHours },
      } = useConfig() as any;
    const [loading, setLoading] = React.useState(true);
    const [phoneChecked, setPhoneChecked] = React.useState(false);
    const [textMsgChecked, setTextMsgChecked] = React.useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (!token) {
            setLoading(false);
            return;
        }

        (async (): Promise<void> => {
            try {
                const { data } = await ApiClient.request(
                    '/getAccountInfo', {
                        method: RestMethod.GET,
                        query: { token },
                        withAuth: true,
                    },
                );

                if (data?.authToken) {
                    login(data.authToken);
                }

                await reload(undefined, true);
            } catch (err) {
                asyncLogger.error(err);
            }

            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleCompleteApplication = async (phoneAllowed?: boolean) => {
        if (!phoneChecked && phoneAllowed === undefined) {
            setShowPopup(true);

            return ;
        }

        setLoading(true);

        if (phoneAllowed !== undefined) {
            setShowPopup(false);
        }

        try {
            if (authorized && customer?.id && customer?.currentLoan?.id) {
                await ApiClient.request(
                    '/updateCustomer', {
                        body: {
                            input: {
                                id: customer.id,
                                contactMethods : {
                                    phone: {
                                        collection: phoneChecked
                                            || phoneAllowed ? true : undefined,
                                        loanStatus: phoneChecked
                                            || phoneAllowed ? true : undefined,
                                        marketing: phoneChecked
                                            || phoneAllowed,
                                    },
                                    textMessage: {
                                        collection: textMsgChecked
                                            ? textMsgChecked : undefined,
                                        loanStatus: textMsgChecked
                                            ? textMsgChecked : undefined,
                                        marketing: textMsgChecked,
                                    },
                                },
                            },
                        },
                        withAuth: true,
                    },
                );

                await ApiClient.request(
                    '/ensureCustomLoanStatuses', {
                        body: {
                            input: {
                                customerId: customer.id,
                                loanId: customer.currentLoan.id,
                                codes: ['CPC'],
                            },
                        },
                        withAuth: true,
                    },
                );
            }
        } catch (err) {
            asyncLogger.error(err);
        }

        await router.push({ pathname: NamedRoute.BANK_VERIFICATION });

        setLoading(false);
    };

    const handleCompleteApplicationClick = async () => {
        await handleCompleteApplication();
    };

    const mobileBreakpoint = theme.breakpoints.down(
        THEME_BREAKPOINTS[KnownBreakpoints.tablet],
    );

    const styles = {
        paper: {
            padding: '35px 115px 40px 45px',
            minHeight: '720px',
            display: 'grid',
            marginTop: '20px',
        },
        stepBox: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginBottom: '10px',
            flexWrap: 'wrap',
            [mobileBreakpoint]: {
                flexDirection: 'column',
            },
        },
        stepItem: {
            textAlign: 'start',
            maxWidth: '280px',
            height: '240px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '20px',
            [theme.breakpoints.down(SUB_LAPTOP)]: {
                width: '30%',
            },
            [mobileBreakpoint]: {
                width: '100%',
                textAlign: 'center',
            },
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35px',
            marginBottom: '35px',
            [mobileBreakpoint]: {
                flexDirection: 'column',
            },
        },
        headerContainer: {
            height: isMobile ? 775 : 300,
            padding: 0,
            [mobileBreakpoint]: {
                height: '775px',
            },
        },
        headerBox: {
            height: '120px',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid white',
            justifyContent: 'space-between',
            borderRadius: '20px',
            padding: '40px',
            [mobileBreakpoint]: {
                textAlign: 'start',
                alignItems: 'start',
                padding: '20px',
                border: 'unset',
                flexDirection: 'column',
            },
        },
        title: {
            fontSize: '30px',
            marginBottom: '0px',
            [mobileBreakpoint]: {
                fontSize: '28px',
                position: 'absolute',
                top: 28,
            },
            [theme.breakpoints.down(EXTRA_SMALL)]: {
                fontSize: '22px',
            },
        },
        subTitle: {
            fontSize: '23px',
            color: 'white',
            [mobileBreakpoint]: {
                fontSize: '19px',
                lineHeight: '26px',
                paddingTop: '11px',
            },
            [theme.breakpoints.down(EXTRA_SMALL)]: {
                fontSize: '17px',
            },
        },
        iconContainer: {
            paddingTop: '28px',
            borderRadius: '12px',
            margin: '0 auto',
            position: 'relative',
        },
        iconLine: {
            position: 'absolute',
            top: '36px',
            bottom: '20px',
            left: '27px',
            transform: 'translateX(-50%)',
            width: '4px',
            backgroundColor: '#FFF',
            zIndex: 0,
            height: '200px',
        },
        completeButton: {
            maxWidth: 350,
            width: '100%',
            height: 78,
            backgroundColor: paletteDark.palette.secondary.main,
            fontSize: '24px',
            fontWeight: 600,
            margin: '30px 0px 30px 0px',
            color: 'white',
            '&:hover': {
                backgroundColor: paletteLight.palette.secondary.light,
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.action.disabled,
            },
            [theme.breakpoints.down(THEME_BREAKPOINTS.tablet)]: {
                margin: '20px 0px',
            },
            [theme.breakpoints.down(SMALL)]: {
                margin: '15px 0px',
            },
            [theme.breakpoints.down(COMPLETE_BUTTON_BREAKPOINT)]: {
                fontSize: '22px',
            },
        },
        avatar: {
            height: '64px',
            width: '64px',
            borderRadius: '50%',
        },
        completeText: {
            color: '#FFF',
            fontWeight: 500,
            fontSize: '19px',
            lineHeight: '26px',
            marginBottom: '20px',
            [theme.breakpoints.down(THEME_BREAKPOINTS.tablet)]: {
                marginTop: '20px',
                fontSize: '16px',
                lineHeight: '16px',
            },
            [theme.breakpoints.down(SMALL)]: {
                fontSize: '12px',
            },
        },
        titleContainer: {
            fontSize: '27px',
            fontWeight: '600',
            lineHeight: '35.16px',
            marginBottom: '1.35em',
            [mobileBreakpoint]: {
                fontSize: '22px',
            },
        },
        mainLoanContainer: {
            width: '100%',
            // height: '627px',
            borderRadius: '20px',
            backgroundColor: isDark
                ? paletteDark.palette.secondary.main
                : paletteLight.palette.action.selected,
            color: theme.palette.text.primary,
            padding: '40px',
            position: 'relative',
            top: '-22px',
            [theme.breakpoints.down(THEME_BREAKPOINTS.laptop)]: {
                height: 'auto',
            },
        },
        newStepIcon: {
            width: '80px',
            height: '80px',
            backgroundColor: paletteDark.palette.primary.dark,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '30px',
        },
        newStepTitle: {
            fontSize: '24px',
            textAlign: 'left',
            width: '100%',
            fontWeight: 600,
            [theme.breakpoints.down(SUB_LAPTOP)]: {
                fontSize: '18px',
            },
            [mobileBreakpoint]: {
                textAlign: 'center',
                fontSize: '18px',
            },
        },
        newStepDescription: {
            fontSize: '16px',
            fontWeight: 500,
            [theme.breakpoints.down(SUB_LAPTOP)]: {
                fontSize: '14px',
            },
            [mobileBreakpoint]: {
                fontSize: '14px',
            },
        },
        newMainText: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '30px',
            [mobileBreakpoint]: {
                fontSize: '20px',
            },
        },
        newButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35px',
        },
        newButton: (theme: Theme) => ({
            width: '461px',
            height: '80px',
            backgroundColor: isDark
                ? paletteDark.palette.primary.main
                : paletteDark.palette.secondary.main,
            fontSize: '24px',
            fontWeight: 600,
            '&.Mui-disabled': {
                backgroundColor: theme.palette.action.disabled,
            },
            [mobileBreakpoint]: {
                width: '100%',
                height: '60px',
                fontSize: '18px',
            },
        }),
        sidebarContainer: {
            // minHeight: '627px',
            width: '325px',
            display: 'flex',
            height: 'auto',
            position: 'relative' as const,
            top: '-22px',
            flexDirection: 'column' as const,
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '20px',
            [theme.breakpoints.down(THEME_BREAKPOINTS.desktop)]: {
                width: '100%',
                position: 'unset' as const,
                flexDirection: 'unset' as const,
                minHeight: '368px',
            },
            [theme.breakpoints.down(SUB_LAPTOP)]: {
                flexDirection: 'unset' as const,
            },
            [mobileBreakpoint]: {
                width: '100%',
                flexDirection: 'column' as const,
                minHeight: '665px',
            },
        },
        supportBox: {
            width: '325px',
            height: '220px',
            backgroundColor: isDark
                ? paletteDark.palette.secondary.main
                : paletteLight.palette.action.selected,
            borderRadius: '20px',
            color: theme.palette.text.primary,
            padding: '35px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down(THEME_BREAKPOINTS.desktop)]: {
                width: '49%',
            },
            [mobileBreakpoint]: {
                width: '100%',
                textAlign: 'center',
                height: 'unset',
            },
        },
        faqBox: {
            width: '325px',
            minHeight: '387px',
            backgroundColor: isDark
                ? paletteDark.palette.secondary.main
                : paletteLight.palette.action.selected,
            color: theme.palette.text.primary,
            borderRadius: '20px',
            padding: '35px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down(THEME_BREAKPOINTS.desktop)]: {
                width: '49%',
            },
            [mobileBreakpoint]: {
                width: '100%',
            },
            [theme.breakpoints.down(EXTRA_SMALL)]: {
                padding: '17px',
            },
        },
        faqTitle: {
            margin: '15px 0px 35px 0px',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '19px',
            [mobileBreakpoint]: {
                width: '100%',
                fontSize: '24px',
                lineHeight: '28px',
                fontWeight: 600,
                margin: '0px 0px 35px 0px',
            },
            [theme.breakpoints.down(EXTRA_SMALL)]: {
                fontSize: '19px',
                lineHeight: '24px',
                marginTop: '22px',
            },
        },
        accordion: {
            backgroundColor: `${ isDark
                ? paletteDark.palette.secondary.main
                : paletteLight.palette.action.selected } !important`,
            width: '287px',
            border: `1px solid ${ paletteLight.palette.info.main } !important`,
            color: theme.palette.text.primary,
            fontWeight: '500',
            lineHeight: '16px',
            [mobileBreakpoint]: {
                width: '100%',
            },
        },
        faqQuestion: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '16px',
            textAlign: 'left',
            [theme.breakpoints.down(EXTRA_SMALL)]: {
                lineHeight: '16px',
                fontSize: '12px',
            },
        },
        faqAnswer: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            letterSpacing: '0.00938em',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '24px',
            [theme.breakpoints.down(EXTRA_SMALL)]: {
                lineHeight: '16px',
                fontSize: '12px',
            },
        },
        accordionIcon: {
            color: theme.palette.text.primary,
            marginLeft: 'auto',
        },
        pageWrapper: {
            width: '100%',
            maxWidth: '1440px',
            margin: '0 auto',
            minHeight: 'calc(100vh - 427px)',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100,
            position: 'relative',
        },
        contentContainer: {
            width: '100%',
            display: 'flex',
            gap: '20px',
            marginTop: '-132px',
            paddingBottom: '60px',
            [theme.breakpoints.down(THEME_BREAKPOINTS.desktop)]: {
                display: 'unset',
            },
            [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
                marginTop: '-80px',
            },
        },
        loanContactTitle: (theme: Theme) => ({
            ...theme.typography.h5,
            lineHeight: '24px',
            color: theme.palette.text.primary,
            marginBottom: '17px',
        }),
        loanContactOfficeHours: (theme: Theme) => ({
            ...theme.typography.body2,
            lineHeight: '14px',
            color: theme.palette.text.primary,
        }),
        checkboxContainer: (theme: Theme) => ({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            '&:hover': { cursor: 'pointer' },
            [theme.breakpoints.down(THEME_BREAKPOINTS.tablet)]: {
                marginBottom: '10px',
                color: theme.palette.common.white,
            },
        }),
        checkboxLabel: {
            fontSize: '14px',
            [theme.breakpoints.down(THEME_BREAKPOINTS.tablet)]: {
                fontSize: '14px',
            },
            [theme.breakpoints.down(SMALL)]: {
                fontSize: '12px',
            },
        },
    };

    const MobileContent = () => <Box sx={ styles.iconContainer }>
        <Box sx={ styles.iconLine }/>
        <Stack
            spacing={ 3 }
            sx={ {
                position: 'relative',
                zIndex: 1,
            } }
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={ 2 }
                justifyContent="flex-start"
            >
                <Avatar
                    sx={ {
                        bgcolor: '#FFF',
                        color: paletteDark.palette.primary.main,
                        width: 56,
                        height: 56,
                        zIndex: 2,
                    } }
                >
                    <AssuredWorkloadIcon
                        sx={ {
                            width: 34,
                            height: 34,
                            color: paletteDark.palette.primary.dark,
                        } }
                    />
                </Avatar>
                <Box>
                    <Typography variant="h6" sx={{
                        color: '#FFF',
                        fontWeight: 700,
                    }}>
                        1. Verify Your Bank Account
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFF' }}>
                        Make sure your account information is accurate so we can
                        send you the funds.
                    </Typography>
                </Box>
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                spacing={ 2 }
                justifyContent="flex-start"
            >
                <Avatar sx={{
                    bgColor: '#FFF',
                    color: paletteDark.palette.primary.main,
                    width: 56,
                    height: 56,
                    zIndex: 2,
                }}>
                    <GradingIcon sx={{
                        width: 34,
                        height: 34,
                        color: paletteDark.palette.primary.dark,
                    }} />
                </Avatar>
                <Box>
                    <Typography variant="h6" sx={{
                        color: '#FFF',
                        fontWeight: 700,
                    }}>
                        2. E-sign Your Docs
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFF' }}>
                        Review your loan documents
                        and terms of payments before
                        final approval.
                    </Typography>
                </Box>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                spacing={ 2 }
                justifyContent="flex-start"
            >
                <Avatar sx={{
                    bgColor: '#FFF',
                    color: paletteDark.palette.primary.main,
                    width: 56,
                    height: 56,
                    zIndex: 2,
                }}>
                    <SupportAgentIcon sx={{
                        width: 34,
                        height: 34,
                        color: paletteDark.palette.primary.dark,
                    }} />
                </Avatar>
                <Box>
                    <Typography variant="h6" sx={{
                        color: '#FFF',
                        fontWeight: 700,
                    }}>
                        3. Confirmation Call
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFF' }}>
                        We may require a quick call to finalize your
                        application, for this, please opt-in below. You can
                        change your permissions at any time.
                    </Typography>
                </Box>
            </Stack>
        </Stack>
        <Typography sx={ styles.completeText }>
            After these steps are completed your loan application will be
            processed immediately.
        </Typography>
        <Box
            sx={ styles.checkboxContainer }
            onClick={ () => {
                if (authorized) {
                    setPhoneChecked(prev => !prev);
                }
            }}
        >
            <Checkbox
                checked={ phoneChecked }
                icon={ <CheckboxIcon stroke={ theme.palette.common.white } /> }
                checkedIcon={ <CheckboxCheckedIcon
                    stroke={ theme.palette.common.white }
                /> }
                indeterminateIcon={ <CheckboxIndeterminateIcon /> }
            />
            <Typography sx={ styles.checkboxLabel }>
                { mockData.phoneCheckbox }
            </Typography>
        </Box>
        <Box
            sx={ styles.checkboxContainer }
            onClick={ () => {
                if (authorized) {
                    setTextMsgChecked(prev => !prev);
                }
            }}
        >
            <Checkbox
                checked={ textMsgChecked }
                icon={ <CheckboxIcon stroke={ theme.palette.common.white } /> }
                checkedIcon={ <CheckboxCheckedIcon
                    stroke={ theme.palette.common.white }
                /> }
                indeterminateIcon={ <CheckboxIndeterminateIcon /> }
            />
            <Typography sx={ styles.checkboxLabel }>
                { mockData.textMsgCheckbox }
            </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
            <Button
                sx={ styles.completeButton }
                onClick={ handleCompleteApplicationClick }
                disabled={ loading || !authorized }
            >
                { mockData.buttonText }
            </Button>
        </Box>
    </Box>;

    const DesktopContent = () => <Avatar
        sx={ styles.avatar }
        alt={ consumerName }
        src="https://creditcube.com/_next/image?url=%2Fuploads%2FCredit_Cube_Model_04b56d7c68.png&w=640&q=75"
    />;

    return (loading
        ? <PageLoader />
        : <BaseLayout
            withActions={ false }
            title={ mockData.title }
            footer={ <Footer withChatWithManager={ false }/> }
            headerBottomSticky={{
                element: <Box sx={ styles.headerContainer }>
                    <Box sx={ styles.headerBox }>
                        <Box>
                            <Typography
                                variant="h2"
                                color="white"
                                gutterBottom
                                sx={ styles.title }
                            >
                                { mockData.title }
                            </Typography>
                            <Typography sx={ styles.subTitle }>
                                { isMobile
                                    ? mockData.mobileSubTitle
                                    : mockData.subTitle }
                            </Typography>
                        </Box>
                        { isMobile
                            ? <MobileContent />
                            : <DesktopContent /> }
                    </Box>
                </Box>,
                zIndex: 10,
                top: 0,
                disableSticky: true,
            } }
        >
            <Head>
                <title>Welcome To { consumerName }</title>
            </Head>
            <Box sx={ styles.pageWrapper }>
                <Box sx={ styles.contentContainer }>
                    { isMobile ? <></> : <Box sx={ styles.mainLoanContainer }>
                        <Typography
                            gutterBottom
                            sx={ styles.titleContainer }
                        >
                            Please follow these 3 steps to finalize your loan
                            application:
                        </Typography>

                        <Box sx={ styles.stepBox }>
                            { mockData.steps.map((step, index) => (
                                <Box key={ index } sx={ styles.stepItem }>
                                    <Box sx={ styles.newStepIcon }>
                                        { step.icon }
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        sx={ styles.newStepTitle }
                                    >
                                        { step.title }
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={ styles.newStepDescription }
                                    >
                                        { step.description }
                                    </Typography>
                                </Box>
                            )) }
                        </Box>

                        <Box textAlign="center">
                            <Typography
                                variant="body1"
                                sx={ styles.newMainText }
                            >
                                After these steps are completed your loan
                                application will be processed immediately.
                            </Typography>
                        </Box>
                        <Box
                            sx={ styles.checkboxContainer }
                            onClick={ () => {
                                if (authorized) {
                                    setPhoneChecked(prev => !prev);
                                }
                            }}
                            marginBottom="15px"
                        >
                            <Checkbox
                                checked={ phoneChecked }
                                disabled={ !authorized }
                            />
                            <Typography sx={ styles.checkboxLabel }>
                                { mockData.phoneCheckbox }
                            </Typography>
                        </Box>
                        <Box
                            sx={ styles.checkboxContainer }
                            onClick={ () => {
                                if (authorized) {
                                    setTextMsgChecked(prev => !prev);
                                }
                            }}
                        >
                            <Checkbox
                                checked={ textMsgChecked }
                                disabled={ !authorized }
                            />
                            <Typography sx={ styles.checkboxLabel }>
                                { mockData.textMsgCheckbox }
                            </Typography>
                        </Box>
                        <Box sx={ styles.newButtonContainer }>
                            <Button
                                variant="contained"
                                sx={ styles.newButton }
                                onClick={ handleCompleteApplicationClick }
                                disabled={ loading || !authorized }
                            >
                                { mockData.buttonText }
                            </Button>
                        </Box>
                    </Box> }
                    <Box sx={ styles.sidebarContainer }>
                        <Box sx={ styles.supportBox }>
                            <Typography
                                component="h5"
                                sx={ styles.loanContactTitle }
                                textAlign={ 'center' }
                            >
                                If you have any questions please contact our
                                customer support at
                            </Typography>
                            <LoanSupportNumberWithIcon marginBottom={ '22px' }/>
                            <Box
                                sx={ styles.loanContactOfficeHours }
                                textAlign="center"
                            >
                                { officeHoursFormat(
                                    officeHours.start,
                                    officeHours.end,
                                ) }
                                <IsOfficeOpened />
                            </Box>
                        </Box>

                        <Box sx={ styles.faqBox }>
                            <Typography variant="h6" sx={ styles.faqTitle }>
                                Frequently Asked Questions
                            </Typography>
                            { faqData.map((faq, index) => (
                                <Accordion
                                    key={ index }
                                    sx={ styles.accordion }
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            <ExpandMoreIcon sx={
                                                styles.accordionIcon
                                            }/>
                                        }
                                        aria-controls={
                                            `panel${ index }-content`
                                        }
                                        id={ `panel${ index }-header` }
                                    >
                                        <Typography sx={ styles.faqQuestion }>
                                            { faq.question }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={ styles.faqAnswer }>
                                        { Array.isArray(faq.answer) ? (
                                            <List sx={{
                                                listStyleType: 'disc',
                                                paddingLeft: '0',
                                                margin: '0',
                                            }}>{ faq.answer.map((
                                                item,
                                                index,
                                            ) => (
                                                <ListItem
                                                    key={ index }
                                                    sx={{
                                                        marginBottom: 0,
                                                        listStyleType: 'disc',
                                                        display: 'list-item',
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        marginLeft: '20px',
                                                    }}
                                                >
                                                    { item }
                                                </ListItem>
                                            )) }
                                            </List>
                                        ) : faq.answer }
                                    </AccordionDetails>
                                </Accordion>
                            )) }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <CustomersLandingPopup
                dialogProps={{
                    open: showPopup,
                    onClose: () => setShowPopup(false),
                }}
                onProceed={ handleCompleteApplication }
            />
        </BaseLayout>
    );
};

export default CustomersLanding;
