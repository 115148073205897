import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../common/constants';
import { CONTENT_MARGIN_TOP } from '../../config';
import { FunctionComponent } from 'react';

const styles = {
    wrapper: (theme: Theme) => ({
        width: '100%',
        display: 'flex',
        gap: '20px',
        marginTop: `-${ CONTENT_MARGIN_TOP }px`,
        paddingBottom: '60px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            flexDirection: 'column',
        },
    }),
    content: (isCreateApplication?: boolean) => ({
        height: '100%',
        width: isCreateApplication ? '100%' : 'unset',
    }),
};

interface MembersAreaPageWrapperProps {
    content: JSX.Element;
    sidebar?: JSX.Element;
    fullWidth?: boolean;
}

const MembersAreaPageWrapper: FunctionComponent<
    MembersAreaPageWrapperProps
> = props => {
    return <Box sx={ styles.wrapper }>
        <Box sx={ styles.content(props.fullWidth) }>
            { props.content }
        </Box>
        { props.sidebar }
    </Box>;
};

export default MembersAreaPageWrapper;
