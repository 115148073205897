import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps, useTheme } from '@mui/material';

const styles = {
    container: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.warning.light,
        borderRadius: '30px',
        width: '100%',
        maxWidth: '256px',
        height: '84px',
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '38px',
        color: theme.palette.secondary.main,
    }),
};

const Promo: FunctionComponent<{
    promoCode: string,
    customStyles?: SxProps<Theme>,
}> = ({ promoCode, customStyles }) => {
    const theme = useTheme();

    return <Box sx={{
        ...styles.container(theme),
        ...(typeof customStyles === 'function'
                ? customStyles(theme)
                : customStyles
        ),
    }}>
        { promoCode }
    </Box>;
};

export default Promo;
