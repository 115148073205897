import React, { FunctionComponent } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../common/helpers';
import HeadphoneKidIcon from '../assets/SvgIcons/HeadphoneKidIcon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../common/constants';

const orange = '#F39200';
const styles = {
    paper: (theme: Theme) => ({
        width: 800,
        height: 600,
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main,
        padding: '109px 130px 90px',
        backgroundImage: 'none',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            width: '100%',
            height: '100%',
            padding: '141px 20px 0',
        },
    }),
    content: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            justifyContent: 'flex-start',
            rowGap: '85px',
        },
    }),
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        width: '99px',
        height: '104px',
    },
    title: (theme: Theme) => ({
        fontSize: '41px',
        fontWeight: 700,
        lineHeight: '40px',
        marginTop: '20px',
        color: orange,
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginTop: '25px',
            fontSize: '30px',
            fontWeight: 800,
            lineHeight: '38px',
        },
    }),
    mainText: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        marginTop: '10px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            ...theme.typography.h4,
            marginTop: '25px',
            lineHeight: '32px',
        },
    }),
    subtitle: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '18px',
        marginTop: '25px',
    }),
    buttonsContainer: (theme: Theme) => ({
        gap: '20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            flexDirection: 'column',
        },
    }),
    banCall: (theme: Theme) => ({
        boxShadow: '0px 3px 3px 0px #283D5B33',
        border: `1px solid ${ theme.palette.text.primary }`,
        maxWidth: '235px',
        width: '100%',
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main,
            color: theme.palette.text.primary,
        },
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            maxWidth: '100%',
        },
    }),
    allowCall: (theme: Theme) => ({
        boxShadow: '0px 3px 3px 0px #283D5B33',
        maxWidth: '280px',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 700,
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            maxWidth: '100%',
            height: '64px',
        },
    }),
};

export interface CustomersLandingPopupProps {
    dialogProps: DialogProps;
    onProceed: (phoneChecked: boolean) => void;
}

const CustomersLandingPopup: FunctionComponent<
    CustomersLandingPopupProps
> = ({ dialogProps, onProceed }) => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );

    const buttons = [
        <Button
            sx={ styles.banCall }
            key={ 0 }
            onClick={ () => onProceed(false) }
        >
            Proceed without phone calls
        </Button>,
        <Button
            sx={ styles.allowCall }
            key={ 1 }
            onClick={ () => onProceed(true) }
        >
            Allow calls & proceed
        </Button>,
    ];
    const buttonsMap = !isMobile ? buttons : buttons.reverse();

    return <Dialog
        fullScreen={ isMobile }
        fullWidth={ isMobile }
        PaperProps={{ sx: styles.paper }}
        { ...dialogProps }
    >
        <Box sx={ styles.content }>
            <Box sx={ styles.mainContent }>
                <HeadphoneKidIcon sx={ styles.icon } fill={ orange }/>
                <Typography
                    component="h2"
                    sx={ styles.title }
                    textAlign="center"
                >
                    Stay Connected!
                </Typography>
                <Typography
                    component="h3"
                    sx={ styles.mainText }
                    textAlign="center"
                >
                    Allowing us to call you will help finalize your
                    application faster.
                </Typography>
                <Typography
                    component="h5"
                    sx={ styles.subtitle }
                    textAlign="center"
                >
                    You will be able to revoke this permission at any time
                </Typography>
            </Box>
            <Box sx={ styles.buttonsContainer }>{ ...buttonsMap }</Box>
        </Box>
    </Dialog>;
};

export default CustomersLandingPopup;
