import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { useConfig } from '../../common/hooks/ConfigProvider';
import { Theme, useTheme } from '@mui/material/styles';
import { useFooterStyles } from '../../common/helpers';
import SmartLink from '../../components/SmartLink';

const styles = {
    text: (theme: Theme, props: Props) => ({
        fontSize: '12px',
        lineHeight: '14.06px',
        fontWeight: theme.typography.fontWeightRegular,
        color: props.color || theme.palette.text.primary,
    }),
};

interface Props {
    color?: string,
    fontSize?: string,
}

const ConsumerYearMobileInfo: FunctionComponent<Props> = props => {
    const theme = useTheme<Theme>();
    const { consumerName } = useConfig();
    const classes = useFooterStyles({ ...props });

    return <Typography sx={{ ...styles.text(theme, props) }}>
        <SmartLink
            href="/"
            className={ classes.footerLink }
        >{ consumerName }</SmartLink> &copy; { new Date().getFullYear() }
    </Typography>;
};

export default ConsumerYearMobileInfo;
