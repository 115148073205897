import React, { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import { isLight } from '../../../common/helpers';
import { KnownBreakpoints } from '../../../common/constants';

const styles = {
    button: (theme: Theme) => ({
        width: '100%',
        height: '80px',
        borderRadius: '30px',
        backgroundColor: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.primary.dark
        ,
        ...theme.typography.h4,
        lineHeight: '28.13px',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.secondary.light
                : theme.palette.primary.main
            ,
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            backgroundColor: theme.palette.secondary.main,
            maxWidth: '350px',
            height: '78px',
            fontSize: '22px',
            lineHeight: '26px',
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    }),
};

const HomeActionButton: FunctionComponent<ButtonProps> = props => {
    return <Button
        { ...props }
        sx={ theme => ({
            ...(styles.button as any)(theme) as SxProps,
            ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
        })}
    >
        { props.children }
    </Button>;
};

export default HomeActionButton;
