import React, { FunctionComponent } from 'react';
import HomePaperPad from '../../PaperPad';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import { OfficeInfoDesktop } from '../../SupportInfo/OfficeInfo';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../../common/constants';
import SmartLink from '../../SmartLink';

const styles = {
    sideBarContainer: (theme: Theme) => ({
        display: 'flex',
        minWidth: '325px',
        maxHeight: '224px',
        position: 'sticky',
        top: '100px',
        zIndex: 100,
        [theme.breakpoints.up(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            maxWidth: '325px',
        },
    }),
    paper: {
        padding: '40px 20px 30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        textAlign: 'center',
    },
    title: (theme: Theme) => ({
        ...theme.typography.h5,
        lineHeight: '24px',
        color: theme.palette.text.primary,
    }),
    phoneInfoContainer: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    phoneIcon: {
        fontSize: '22px',
    },
    phoneNumber: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        color: theme.palette.text.primary,
    }),
    officeInfo: (theme: Theme) => ({
        ...theme.typography.body2,
        textAlign: 'center',
    }),
};

const SurveySideBar: FunctionComponent = () => {
    const { consumerOperationsDetails: { phoneNumber }} = useConfig() as any;

    const theme = useTheme<Theme>();

    return <Box sx={ styles.sideBarContainer }>
        <HomePaperPad sx={ styles.paper }>
            <Typography component="h5" sx={ styles.title }>
                If you have any questions please contact our customer
                support at
            </Typography>
            <SmartLink
                href={ `tel:${ phoneNumber }` }
                style={ (styles.phoneNumber as any)(theme) }
            >
                { phoneNumber }
            </SmartLink>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <OfficeInfoDesktop styles={ styles.officeInfo(theme) }/>
            </Box>
        </HomePaperPad>
    </Box>;
};

export default SurveySideBar;
