import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, TextField } from '@mui/material';
import MobileSupportInfo from '../MobileSupportInfo';
// import ChatWithManager from '../../../ChatWithManager';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../../../common/helpers';
import MobileSubmitButton from './MobileSubmitButton';
import { InitProps } from '../index';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';

const styles = {
    mobileContent: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
        padding: '25px 20px 45px',
    }),
    mobileSubTitleInfo: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
    },
    mobileSubTitlePromoInfo: (theme: Theme) => ({
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        color: theme.palette.warning.light,
    }),
    mobileTitle: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
        marginTop: '5px',
    },
    answerField: (theme: Theme, error?: string) => ({
        width: '100%',
        height: '270px',
        '& > div': {
            padding: '25px 35px',
            backgroundColor: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main
            ,
            borderRadius: '20px',
            alignItems: 'start',
            overflow: 'auto',
            height: '100%',
        },
        '& > div > textarea': {
            color: isLight(theme) ? '#404040' : theme.palette.text.primary,
        },
        '& > div > fieldset': {
            borderRadius: '20px',
            border: `${ error
                ? `3px solid ${ theme.palette.error.main } !important`
                : '1px' }`,
        },
    }),
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    mobileSupportInfo: {
        padding: '40px 20px 16px',
    },
    badge: {
        position: 'relative',
    },
    authFormContainer: {
        padding: '0 20px',
    },
};

const MobileInitMainContent: FunctionComponent<{
    subTitleInfo: string,
    subTitlePromoInfo: string,
    placeholder: string,
    buttonText: string,
} & InitProps> = ({
    subTitleInfo,
    subTitlePromoInfo,
    placeholder,
    buttonText,
    error,
    question,
    answer,
    setAnswer,
    onClick,
    loading,
    setError,
}) => {
    const theme = useTheme();

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(undefined);

        if (question?.id) {
            setAnswer({
                text: event.target.value,
                questionId: question.id,
            });
        }
    };

    return <Box>
        <Box sx={ styles.mobileContent }>
            <Typography
                variant="h3"
                color="white"
                sx={ styles.mobileSubTitleInfo }
            >
                { subTitleInfo }
                <Typography
                    sx={ styles.mobileSubTitlePromoInfo }
                    component="span"
                >
                    { subTitlePromoInfo }
                </Typography>
            </Typography>
            <Typography
                variant="h2"
                color="white"
                sx={ styles.mobileTitle }
            >
                { question?.text }
            </Typography>
            <Box marginTop="30px">
                { !!error && <Alert
                    severity="error"
                    iconMapping={ { error: <WarningIcon /> } }
                >
                    { error }
                </Alert> }
                <TextField
                    sx={ styles.answerField(theme, error) }
                    multiline
                    placeholder={ placeholder }
                    value={ answer?.text }
                    onChange={ handleAnswerChange }
                />
            </Box>
            <Box sx={ styles.buttonContainer }>
                <MobileSubmitButton
                    text={ buttonText }
                    buttonProps={{
                        disabled: !answer?.text || loading,
                        onClick: onClick,
                    }}
                />
            </Box>
        </Box>
        <Box sx={ styles.mobileSupportInfo }>
            <MobileSupportInfo/>
            {/*Temporarily removed at the request of Collections*/}
            {/*<ChatWithManager sx={ styles.badge }/>*/}
        </Box>
    </Box>;
};

export default MobileInitMainContent;
