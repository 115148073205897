import React, { Fragment, FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import OfficeInfo from '../../SupportInfo/OfficeInfo';
import SmartLink from '../../SmartLink';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useConfig } from '../../../common/hooks/ConfigProvider';

const styles = {
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '32px',
        marginBottom: '10px',
        color: theme.palette.text.primary,
    }),
    textInfo: (theme: Theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '16.41px',
        marginBottom: '17px',
        color: theme.palette.text.primary,
    }),
    link: (theme: Theme) => ({
        cursor: 'pointer',
        color: theme.palette.text.primary,
        textDecorationColor: theme.palette.text.primary,
    }),
};

const MobileSupportInfo: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    const { consumerOperationsDetails } = useConfig();
    const { supportEmail, phoneNumber } = consumerOperationsDetails as any;

    return <Fragment>
        <Typography sx={ styles.title }>
            Have a question? Contact us!
        </Typography>
        <OfficeInfo withoutCenteredOfficeStatus/>
        <Typography sx={ styles.textInfo }>
            By Email: <SmartLink
                style={ (styles.link as any)(theme) }
                href={ `mailto:${ supportEmail }` }
            >
                { supportEmail }
            </SmartLink>
        </Typography>
        <Typography sx={ styles.textInfo } marginBottom="33px">
            By Phone: <SmartLink
                href={ `tel:${ phoneNumber }` }
                style={ (styles.link as any)(theme) }
            >
                { phoneNumber }
            </SmartLink>
        </Typography>
    </Fragment>;
};

export default MobileSupportInfo;
