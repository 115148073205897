import React, { FunctionComponent } from 'react';
import HomePaperPad from '../../../PaperPad';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SurveySaveProps } from '../index';
import Promo from '../Promo';
import DesktopSubmitButton from './DesktopSubmitButton';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import { useRouter } from 'next/router';

const styles = {
    paper: {
        padding: '55px 50px 30px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        rowGap: '70px',
    },
    topContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '35.16px',
    },
    bottomContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.41px',
    },
};

const DesktopSavedMainContent: FunctionComponent<SurveySaveProps> = ({
    title,
    discount,
    discountInfo,
    discountUsageInfo,
    discountLoanInfo,
    buttonText,
}) => {
    const router = useRouter();

    return <HomePaperPad sx={ styles.paper }>
        <Box sx={ styles.contentContainer }>
            <Box sx={ styles.topContent }>
                <Typography sx={ styles.text } variant="h2" textAlign="center">
                    { title }
                </Typography>
                <Typography
                    sx={ styles.text }
                    variant="h2"
                    textAlign="center"
                    marginTop="15px"
                >
                    { discountInfo }
                </Typography>
                <Promo
                    promoCode={ discount }
                    customStyles={{ marginTop: '25px' }}
                />
                <Typography
                    sx={ styles.text }
                    variant="h2"
                    textAlign="center"
                    marginTop="50px"
                >
                    { discountLoanInfo }
                </Typography>
                <DesktopSubmitButton
                    text={ buttonText }
                    customStyles={{ marginTop: '30px' }}
                    buttonProps={{ onClick: () => router.push(
                        NamedRoute.HOME + '?dialog=apply',
                    )}}
                />
            </Box>
            <Box sx={ styles.bottomContent }>
                { discountUsageInfo }
            </Box>
        </Box>
    </HomePaperPad>;
};

export default DesktopSavedMainContent;
